<script lang="jsx">
import { Icon } from '@shein-aidc/icon-vue2'
export default {
  name: 'SheinStar',
  functional: true,
  components: {
    Icon
  },
  props: {
    grade: {
      type: [Number, String],
      default: 0
    },
    size: {
      type: String,
      default: '15'
    }
  },
  render(h, { props }) {
    const { size, grade } = props
    const name = `sui_icon_star_5_${size}px_2`
    const decimal = Number(parseFloat(grade).toFixed(2).split('.')[1]) || 0
    const color = '#facf19'
    const renderDecimal = () => {
      if (decimal > 0) {
        if (decimal <= 25) {
          return <Icon  name={`sui_icon_star_2_${size}px_2`} size={size} color={color} />
        } else if (decimal <= 50) {
          return <Icon name={`sui_icon_star_3_${size}px_2`} size={size} color={color}  />
        } else if (decimal <= 99) {
          return <Icon name={`sui_icon_star_4_${size}px_2`} size={size} color={color}  />
        }
      }
    }
    const renderFill = () => {
      const fillNum = 5 - parseInt(grade) - (decimal > 0 ? 1 : 0)
      return Array.from({ length: fillNum }, (v) => {
        return <Icon key={v} name={`sui_icon_star_1_${size}px_2`} size={size} color={color} />
      })
    }
    return [
      Array.from({ length: grade }, (v) => {
        return <Icon key={v} name={name} size={size} color={color} />
      }),
      renderDecimal(),
      renderFill()
    ]
  }
}
</script>
